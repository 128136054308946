


















































































































































import {Component, Prop, VModel, Vue, Watch} from 'vue-property-decorator';
import {namespace} from 'vuex-class';
import {WorkSessionFilterData} from '@/helper/WorksessionFilterData';
import {jobOccurrenceStoreActions} from '@/stores/jobOccurrence.store.ts';
import {tenantStoreGetter} from '@/stores/tenant.store.ts';
import WorkSession from '@/models/WorkSession';
import Tenant from '@/models/Tenant';
import moment from 'moment';

const JobOccurrenceStore = namespace('jobOccurrence');
const TenantStore = namespace('tenant');
@Component({
  components: {
    MenuWithPicker: () => import(
        '@/components/shared/MenuWithPicker.component.vue'),
    RJAutocomplete: () => import(
        '@/components/shared/custom-vuetify/RJAutocomplete.vue'),
  },
})

export default class TimeTrackingFilterComponent extends Vue {

  @JobOccurrenceStore.Action(jobOccurrenceStoreActions.LOAD_WORK_SESSIONS_ACTION)
  private loadWorkSessions!: (filterData: WorkSessionFilterData) => Promise<WorkSession[]>;

  @TenantStore.Getter(tenantStoreGetter.ACTIVE_TENANT)
  private tenant!: Tenant;

  @VModel({required: true})
  private pickerDate!: string;

  private workSessionStatus: 'all' | 'inProgress' | 'goodCondition' | 'badCondition' = 'all';

  private workSessionFilterData!: WorkSessionFilterData;

  private mode: 'day' | 'week' | 'custom' = 'week';

  private allUsers: string[] = [];
  private allLocations: string[] = [];
  private allWorkSessions: WorkSession[] = [];

  private created() {
    this.resetFilter();
    this.loadAllWorkSession();
  }

  private resetFilter() {
    this.mode = 'week';
    this.workSessionFilterData = new WorkSessionFilterData();
    this.workSessionFilterData.tenant = this.tenant.id;
    this.pickerDate = moment().format(this.$t('GENERAL.DATE_FORMATS.PICKER_DATE').toString());
  }

  @Watch('pickerDate')
  private async loadAllWorkSession() {
    this.$emit('start');
    this.allWorkSessions = [];
    this.allLocations = [];
    this.allUsers = [];

    if (this.mode === 'week' || this.mode === 'day') {
      this.workSessionFilterData.createdAtFrom = moment(this.pickerDate).startOf(this.mode).toISOString();
      this.workSessionFilterData.createdAtTo = moment(this.pickerDate).endOf(this.mode).toISOString();
    }

    try {
      this.allWorkSessions = await this.loadWorkSessions(this.workSessionFilterData);
    } catch (e) {
      console.error(e);
    }

    this.allWorkSessions.forEach((workSession) => {
      workSession.getRenderData();
      if (!this.allUsers.includes(workSession.user.fullName)) {
        this.allUsers.push(workSession.user.fullName);
      }
      if (!this.allLocations.includes(workSession.location.name!)) {
        this.allLocations.push(workSession.location.name!);
      }});

    this.sortWorkSessions();

    this.$emit('end');
    this.filtered();
  }

  private sortWorkSessions() {
    // sort the data
    this.allWorkSessions.sort((a, b) => {
      return moment(a.startDate).diff(moment(b.startDate));
    });
  }

  private filtered() {
    let filtered: WorkSession[] = [...this.allWorkSessions];
    if (this.workSessionFilterData.user?.length) {
      filtered = filtered.filter((workSession) => workSession.user.fullName === this.workSessionFilterData.user );
    }
    if (this.workSessionFilterData.location?.length) {
      filtered = filtered.filter((workSession) => workSession.location.name === this.workSessionFilterData.location );
    }
    if (this.workSessionFilterData.hasComments) {
      filtered = filtered.filter((workSession) => workSession.renderData.comments);
    }
    if (this.workSessionFilterData.hasNotes) {
      filtered = filtered.filter((workSession) => workSession.notes.length);
    }
    if (this.workSessionFilterData.hasImages) {
      filtered = filtered.filter((workSession) => workSession.renderData.images);
    }
    if (this.startTime) {
      const startHour = parseInt(this.startTime!.substring(0, 2));
      const startMin = parseInt(this.startTime!.substring(3, 5));
      const startTime = startHour * 60 + startMin;

      filtered = filtered.filter((workSession) => startTime < (workSession.startTime.hour * 60 + workSession.startTime.minute),
      );
    }

    if (this.endTime) {
      const endHour = parseInt(this.endTime.substring(0, 2));
      const endMin = parseInt(this.endTime.substring(3, 5));
      const endTime = endHour * 60 + endMin;

      filtered = filtered.filter((workSession) => (
              endTime > (workSession.endTime ?
                  workSession.endTime.hour * 60 + workSession.endTime.minute
                  : workSession.startTime.hour * 60 + workSession.startTime.minute)
          ),
      );
    }

    if (this.workSessionStatus !== 'all') {
      if (this.workSessionStatus === 'inProgress') {
        filtered = filtered.filter((workSession) => !workSession.endTime);
      } else if (this.workSessionStatus === 'goodCondition') {
        filtered = filtered.filter((workSession) => workSession.renderData.conditions.positives);
      } else {
        filtered = filtered.filter((workSession) => workSession.renderData.conditions.negatives);
      }
    }
    this.$emit('Filtered', filtered);
  }

  private nowISO() {
    return moment().toISOString();
  }

  public startTime: string | null = null;
  public endTime: string | null = null;


  private changeMode(mode: 'day' | 'week' | 'custom') {
    this.mode = mode;
    if (mode === 'week') {
      this.pickerDate = moment(this.pickerDate)
          .startOf('week').format(this.$t('GENERAL.DATE_FORMATS.PICKER_DATE').toString());
    }
    this.$emit('mode', this.mode);
    this.loadAllWorkSession();
  }

  private customText() {
    if (this.mode === 'custom') {
      return this.workSessionFilterData.individualDates?.length + ' ' + this.$t('GENERAL.CUSTOM') + ' ' + this.$t('GENERAL.DAY') + ' ';
    } else if ( this.mode === 'week') {
      return this.$t('GENERAL.CALENDAR_WEEK', {week: moment(this.pickerDate).week()}).toString();
    }
    return moment(this.pickerDate).format(this.$t('GENERAL.DATE_FORMATS.DATE').toString());
  }

  private changeStatus(newStatus: 'all' | 'inProgress' | 'goodCondition' | 'badCondition') {
    this.workSessionStatus = newStatus;
    this.filtered();
  }
}
