export class WorkSessionFilterData {
  public createdAtFrom?: string;
  public createdAtTo?: string;
  public individualDates: string[] = [];

  public tenant?: string;
  public user?: string;
  public location?: string;

  public hasNotes: boolean = false;
  public hasComments: boolean = false;
  public hasImages: boolean = false;

  // bad var
  public users?: string[] | null;
  public managers?: string[] | null;
  public customers?: string[] | null;
  public locations?: string[] | null;
}
